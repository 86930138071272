<template>
  <div>
    <b-container class="mt-4">
      <b-row>
        <b-col style="text-align: center;">
          <span style="font-size: 120px;">404</span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'ErrorView',
  mounted() {
    window.document.title = 'Spread Racer — Helper'
  }
}
</script>

<template>
	<div>
		<b-container fluid>
      <b-overlay :show="isPageLoaded == 0" :opacity="1" variant="dark" no-wrap>
      </b-overlay>
      <template v-if="isPageLoaded == 2">
        <b-row>
          <b-col class="text-center mt-4">
            <h3>Произошла ошибка при загрузке страницы.</h3>
            <h5>Попробуйте перезагрузить страницу, в случае неудачи обратитесь в поддержку.</h5>
          </b-col>
        </b-row>
      </template>
      <div :class="{'d-none': isPageLoaded != 1}">
        <b-row ref="topbar1" class="justify-content-center topbar2 sticky">
          <b-col lg="4" class="text-center" style="margin-bottom: 0px">
            <template v-if="Boolean(priceBkSell)">
              <small>б/у: <span v-b-tooltip.hover title="Цена безубытка">{{ priceBkSell }} </span> <span v-b-tooltip.hover title="Объём на ценовом уровне">({{ Math.round(amountAvgSell, 0) }} USDT)</span>{{ priceBkWarning }}</small>
            </template>
          </b-col>
          <b-col lg="4" class="text-center" style="margin-bottom: 0px">
            <small>{{ arb.asset.toUpperCase() }}/USDT</small>
          </b-col>
          <b-col lg="4" class="text-center" style="margin-bottom: 0px">
            <template v-if="Boolean(priceFSell)">
              <small>фьюч: <span v-b-tooltip.hover title="Цена фьючерса">{{ priceFSell }} </span> <span v-b-tooltip.hover title="Объём на ценовом уровне">({{ Math.round(maxAmountFSell, 0) }} USDT)</span> {{ spreadF ? '/' : '' }} <span v-b-tooltip.hover title="Спред между покупкой и фьючом">{{ spreadF }}{{ spreadF ? '%' : '' }}</span></small>
            </template>
          </b-col>
        </b-row>
        <template v-if="priceBuy != null && priceSell != null">
          <b-row class="justify-content-center topbar sticky" :style="{ top: topbarHeight + 'px' }">
            <template v-if="!isNaN(spread)">
              <b-col lg="5" md="3" xs="12" class="h3 text-center" style="margin-bottom: 0px">
                <template v-if="priceBuy == 0">
                  —
                </template>
                <template v-else>
                  <span :class="{'clickable': true, 'price-arrow-red': priceBuyOpts.isRed, 'price-arrow-green': priceBuyOpts.isGreen}" v-b-tooltip.hover title="Цена покупки" @click="copy($event)">{{ priceBuyOpts.arrowChar }} {{ noExponents(priceBuy) }}</span> <span v-b-tooltip.hover title="Объём на ценовом уровне" class="h5">({{ Math.round(maxAmountBuy, 0) }} USDT,</span> <span class="h5"><a class="exchange-link" target="_blank" :href="getSymbolPage(arb.exBuy, `${arb.asset}/USDT`)">{{arb.exBuy}}</a>)</span> <span v-b-modal.lock-price-modal><b-icon icon="lock-fill"></b-icon></span>
                </template>
              </b-col>
              <b-col lg="2" class="text-center h3" style="margin-bottom: 0px">
                <template v-if="priceBuy == 0 || priceSell == 0">
                  —
                </template>
                <template v-else>
                  <b v-b-tooltip.hover title="Спред между покупкой и продажей">{{ spread }}%</b>
                </template>
              </b-col>
              <b-col lg="5" md="3" xs="12" class="h3 text-center" style="margin-bottom: 0px">
                <template v-if="priceSell == 0">
                  —
                </template>
                <template v-else>
                  <span :class="{'clickable': true, 'price-arrow-red': priceSellOpts.isRed, 'price-arrow-green': priceSellOpts.isGreen}" v-b-tooltip.hover title="Цена продажи" @click="copy($event)">{{ priceSellOpts.arrowChar }} {{ noExponents(priceSell) }}</span> <span v-b-tooltip.hover title="Объём на ценовом уровне" class="h5">({{ Math.round(maxAmountSell, 0) }} USDT,</span> <span class="h5"><a class="exchange-link" target="_blank" :href="getSymbolPage(arb.exSell, `${arb.asset}/USDT`)">{{arb.exSell}}</a>)</span>
                </template>
              </b-col>
            </template> 
            <template v-else>
              <b-col class="h3 text-center" style="margin-bottom: 0px">
                <b>Нет объёмов!</b>
              </b-col>
            </template>
          </b-row>
        </template>
        <div stlye="z-index: 1">
          <b-row class="justify-content-center">
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.mexc.show}">
              <div ref="block_bar_mexc" class="position-relative">
                <b-overlay :show="bars.mexc.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.mexc.bg" title-txt="Mexc" :width="bars.mexc.width" :height="300" :data="bars.mexc"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.mexc.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.mexc.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.mexc.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.mexc.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.bitrue.show}">
              <div ref="block_bar_bitrue" class="position-relative">
                <b-overlay :show="bars.bitrue.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.bitrue.bg" title-txt="Bitrue" :width="bars.bitrue.width" :height="300" :data="bars.bitrue"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.bitrue.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.bitrue.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.bitrue.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.bitrue.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.bybit.show}">
              <div ref="block_bar_bybit" class="position-relative">
                <b-overlay :show="bars.bybit.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.bybit.bg" title-txt="Bybit" :width="bars.bybit.width" :height="300" :data="bars.bybit"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.bybit.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.bybit.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.bybit.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.bybit.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.poloniex.show}">
              <div ref="block_bar_poloniex" class="position-relative">
                <b-overlay :show="bars.poloniex.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.poloniex.bg" title-txt="Poloniex" :width="bars.poloniex.width" :height="300" :data="bars.poloniex"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.poloniex.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.poloniex.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.poloniex.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.poloniex.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <!-- <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.lbank.show}">
              <div ref="block_bar_lbank" class="position-relative">
                <b-overlay :show="bars.lbank.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.lbank.bg" title-txt="LBank" :width="bars.lbank.width" :height="300" :data="bars.lbank"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.lbank.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.lbank.loading}">
                  <span class="bid">{{ bars.lbank.volBuy }}</span> USDT / <span class="ask">{{ bars.lbank.volSell }}</span> USDT
                </div>
              </div>
            </b-col> -->
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.kucoin.show}">
              <div ref="block_bar_kucoin" class="position-relative">
                <b-overlay :show="bars.kucoin.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.kucoin.bg" title-txt="Kucoin" :width="bars.kucoin.width" :height="300" :data="bars.kucoin"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.kucoin.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.kucoin.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.kucoin.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.kucoin.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.bitget.show}">
              <div ref="block_bar_bitget" class="position-relative">
                <b-overlay :show="bars.bitget.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.bitget.bg" title-txt="BitGet" :width="bars.bitget.width" :height="300" :data="bars.bitget"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.bitget.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.bitget.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.bitget.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.bitget.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.bingx.show}">
              <div ref="block_bar_bingx" class="position-relative">
                <b-overlay :show="bars.bingx.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.bingx.bg" title-txt="BingX" :width="bars.bingx.width" :height="300" :data="bars.bingx"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.bingx.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.bingx.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.bingx.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.bingx.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.bitmart.show}">
              <div ref="block_bar_bitmart" class="position-relative">
                <b-overlay :show="bars.bitmart.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.bitmart.bg" title-txt="BitMart" :width="bars.bitmart.width" :height="300" :data="bars.bitmart"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.bitmart.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.bitmart.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.bitmart.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.bitmart.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.gate.show}">
              <div ref="block_bar_gate" class="position-relative">
                <b-overlay :show="bars.gate.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.gate.bg" title-txt="Gate" :width="bars.gate.width" :height="300" :data="bars.gate"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.gate.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.gate.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.gate.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.gate.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.okx.show}">
              <div ref="block_bar_okx" class="position-relative">
                <b-overlay :show="bars.okx.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.okx.bg" title-txt="OkX" :width="bars.okx.width" :height="300" :data="bars.okx"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.okx.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.okx.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.okx.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.okx.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.htx.show}">
              <div ref="block_bar_htx" class="position-relative">
                <b-overlay :show="bars.htx.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.htx.bg" title-txt="HTX" :width="bars.htx.width" :height="300" :data="bars.htx"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.htx.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.htx.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.htx.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.htx.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
            <b-col :xl="defXl" :lg="defXl" md="6" sm="12" :class="{'m-0': true, 'p-0': true, 'd-none': !bars.coinex.show}">
              <div ref="block_bar_coinex" class="position-relative">
                <b-overlay :show="bars.coinex.loading" spinner-small rounded="sm" variant="dark" :opacity="0.2" no-wrap>
                </b-overlay>
                <trading-vue :color-back="bars.coinex.bg" title-txt="CoinEx" :width="bars.coinex.width" :height="300" :data="bars.coinex"></trading-vue>
              </div>
              <div class="text-center volume-bar position-relative">
                <b-overlay :show="bars.coinex.loading" spinner-small rounded="sm" variant="dark" :opacity="0.0" no-wrap>
                </b-overlay>
                <div :class="{'invisible': bars.coinex.loading}">
                  <span v-b-tooltip.hover title="Объём торгов за 24 часа" class="total">{{ numberWithCommas(bars.coinex.volTotal) }} USDT</span> — <span v-b-tooltip.hover title="Объём продаж за 15 минут" class="total">{{ numberWithCommas(bars.coinex.sellsSum) }} USDT</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- <b-row class="justify-content-center">
          <b-col class="text-center">
            <b-form-select v-model="tf" :options="availableTfs"></b-form-select>
          </b-col>
        </b-row> -->
      </div>
    </b-container>
    <b-modal centered id="lock-price-modal" hide-header-close hide-header hide-footer>
      <b-form @submit.prevent="onLockFormSubmit">
        <b-form-input class="mb-2" v-model="lockedPriceValue_" min="0" step="0.000000000000000001" type="number" placeholder="Введите цену покупки"></b-form-input>
        <b-row>
          <b-col sm="3" class="mt-2">
            <b-button type="button" variant="danger" @click="unlockPrice" style="width: 100%;" size="sm">Открепить</b-button>
          </b-col>
          <b-col sm="3" class="mt-2" offset-sm="6">
            <b-button type="submit" variant="success" style="width: 100%;" size="sm">Закрепить</b-button>
          </b-col>
        </b-row>
      </b-form>         
    </b-modal>
	</div>
</template>

<script>
import {apiRequest} from '../main.js'

export default {
  name: 'SpreadView',
  data() {
    return {
      tf: '5m',
      priceBuyOpts: {
        isRed: false,
        isGreen: false,
        arrowChar: ''
      },
      lockedPriceValue: null,
      lockedPriceValue_: null,
      priceSellOpts: {
        isRed: false,
        isGreen: false,
        arrowChar: ''
      },
      arb: {
        asset: '',
        amount: null,
        exBuy: null,
        exSell: null,
        withdrawalFeePercent: null,
        withdrawalFeeFixed: null,
        spotSummaryFee: null
      },
      defXl: 4,
      exs: [],
      availableTfs: [
        { value: '4h', text: '4 часа' },
        { value: '1h', text: '1 час' },
        { value: '30m', text: '30 минут' },
        { value: '15m', text: '15 минут' },
        { value: '5m', text: '5 минут' },
        { value: '1m', text: '1 минута' },
      ],
      totalExs: ['bingx', 'bitget', 'bitmart', 'gate', 'htx', 'kucoin', 'mexc', 'okx', 'coinex'],
      priceBuy: null,
      score: null,
      priceSell: null,
      priceFSell: null,
      priceBkSell: null,
      priceAvgSell: null,
      priceBkWarning: '—',
      amountAvgSell: null,
      topbarHeight: 27,
      pdi: null,
      maxAmountBuy: null,
      maxAmountSell: null,
      maxAmountFSell: null,
      spread: null,
      spreadF: null,
      isPageLoaded: 0,
      bars: {
        mexc: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        kucoin: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        bitget: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        bingx: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        bitmart: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        bitrue: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        bybit: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        poloniex: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        gate: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        okx: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        htx: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        },
        coinex: {
          ohlcv: [],
          loading: false,
          show: false,
          width: 0,
          bg: '#121826',
          volBuy: null,
          volSell: null,
          sellsSum: null,
          volTotal: null
        }
      }
    }
  },
  mounted() {
    for(let k in this.arb) {
      if (k in this.$route.params) {
        this.arb[k] = this.$route.params[k]
      }
    }
    let assetUpper = this.arb.asset.toUpperCase()
    window.document.title = `Spread Racer — ${assetUpper}`
    this.priceBuy = 0
    this.priceSell = 0
    this.spread = 0
    window.addEventListener('resize', this.fixBarsSize)
    this.arb.withdrawalFeePercent = 'wfp' in this.$route.query ? Number(this.$route.query.wfp) : null
    this.arb.withdrawalFeeFixed = 'wff' in this.$route.query ? Number(this.$route.query.wff) : null
    this.arb.spotSummaryFee = 'ssf' in this.$route.query ? Number(this.$route.query.ssf) : null
    this.fetchCheck(() => {
      this.fetchData(() => {
        this.isPageLoaded = 1
        this.fetchKlines(true)
      })
    }, () => {
      this.isPageLoaded = 2
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.fixBarsSize)
  },
  methods: {
    fixBarsSize: function() {
      /* eslint-disable */
      try {
        this.topbarHeight = this.$refs.topbar1.clientHeight
      } catch {
      } finally {
      }
      try {
        this.bars.mexc.width = this.$refs.block_bar_mexc.clientWidth
        // this.bars.lbank.width = this.$refs.block_bar_lbank.clientWidth
        this.bars.kucoin.width = this.$refs.block_bar_kucoin.clientWidth
        this.bars.bitget.width = this.$refs.block_bar_bitget.clientWidth
        this.bars.bingx.width = this.$refs.block_bar_bingx.clientWidth
        this.bars.bitmart.width = this.$refs.block_bar_bitmart.clientWidth
        this.bars.gate.width = this.$refs.block_bar_gate.clientWidth
        this.bars.okx.width = this.$refs.block_bar_okx.clientWidth
        this.bars.htx.width = this.$refs.block_bar_htx.clientWidth
        this.bars.coinex.width = this.$refs.block_bar_coinex.clientWidth
        this.bars.bitrue.width = this.$refs.block_bar_bitrue.clientWidth
        this.bars.bybit.width = this.$refs.block_bar_bybit.clientWidth
        this.bars.poloniex.width = this.$refs.block_bar_poloniex.clientWidth
      } catch {
      } finally {
      }
    },
    copy: function(event) {
      var inp = document.createElement('input')
      document.body.appendChild(inp)
      inp.value = event.target.innerText.substr(2)
      inp.select()
      document.execCommand('copy',false)
      inp.remove()
    },
    fetchCheck: function(callback, failed) {
      this.totalExs.forEach((el) => {
          this.bars[el].loading = true
      })
      apiRequest.get(`check/${this.arb.asset}`)
      .then(response => {
        this.exs = response.data
        if(this.exs.length == 2) {
          this.defXl = 6
        }
        if(this.exs.length == 1) {
          this.defXl = 12
        }
        if(this.exs.length <= 1) {
          failed()
        } else {
          this.exs.forEach((el, idx) => {
            this.bars[el].show = true
            if(idx === this.exs.length-1){
              callback()
            }
          })
        }
      })
      .catch((e) => {
        this.isPageLoaded = 2
        console.log("200 e: ", e);
      })
    },
    numberWithCommas(x) {
      if(x == null) {
        return x
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    fetchKlines: function(spinner=false) {
      let done_klines = 0
      for (const exch of this.exs) {
        if(spinner) {
          this.bars[exch].loading = true
        }
        apiRequest.get(`klines/${this.arb.asset}/${exch}/${this.tf}`)
        .then(response => {
          if(exch == this.arb.exBuy) {
            this.bars[exch].bg = '#12261d'
          } else if(exch == this.arb.exSell) {
            this.bars[exch].bg = '#2b1515'
          }
          this.bars[exch].loading = false
          this.bars[exch].ohlcv = response.data['data']['klines']
          this.bars[exch].volBuy = Number(response.data['data']['volumes']['buy']).toFixed(2)
          this.bars[exch].volSell = Number(response.data['data']['volumes']['sell']).toFixed(2)
          this.bars[exch].volTotal = Number(response.data['data']['volumes']['total']).toFixed(2)
          this.bars[exch].sellsSum = Number(response.data['data']['sells_sum']).toFixed(2)
          done_klines += 1
          if(done_klines == this.exs.length) {
            this.fixBarsSize()
            setTimeout(this.fetchKlines, 5000)
          }
        })
        .catch((e) => {
          console.log("200 e: ", e);
          // setTimeout(this.fetchKlines, 3000)
        })
      }
    },
    calcDigits(value) {
      try {
        return value.replace(/0+$/, '').split('.')[1].length
      } catch {
        return 0
      }
    },
    getSymbolPage: function(client, symbol) {
      client = client.toLowerCase()
      let symbol_parts = symbol.split('/')
      let sym_l = symbol_parts[0]
      let sym_r = symbol_parts[1]
      if(client == 'lbank') {
        return `https://www.lbank.com/trade/${sym_l}_${sym_r}/`
      } else if(client == 'mexc') {
        return `https://www.mexc.com/exchange/${sym_l}_${sym_r}/`
      } else if(client == 'kucoin') {
        return `https://www.kucoin.com/trade/${sym_l}-${sym_r}`
      } else if(client == 'gate') {
        return `https://www.gate.io/trade/${sym_l}_${sym_r}`
      } else if(client == 'bitget') {
        return `https://www.bitget.com/spot/${sym_l}${sym_r}`
      } else if(client == 'bingx') {
        return `https://bingx.com/en-us/spot/${sym_l}${sym_r}`
      } else if(client == 'bitmart') {
        return `https://www.bitmart.com/trade/en-US?layout=pro&theme=dark&symbol=${sym_l}_${sym_r}`
      } else if(client == 'okx') {
        return `https://www.okx.com/trade-spot/${sym_l}-${sym_r}`
      } else if(client == 'coinex') {
        return `https://www.coinex.com/exchange/${sym_l}-${sym_r}`
      } else if(client == 'bitrue') {
        return `https://www.bitrue.com/ru/trade/${sym_l}_${sym_r}`
      } else if(client == 'bybit') {
        return `https://www.bybit.com/en/trade/spot/${sym_l}/${sym_r}`
      } else if(client == 'poloniex') {
        return `https://poloniex.com/trade/${sym_l}_${sym_r}?type=spot`
      } else if(client == 'htx') {
        return `https://www.htx.com/en-us/trade/${sym_l}_${sym_r}`.toLowerCase()
      }
    },
    unlockPrice: function() {
      this.lockedPriceValue = null
      this.$bvModal.hide('lock-price-modal')
    },
    onLockFormSubmit: function() {
      try {
        let value = Number(this.lockedPriceValue_)
        if(value) {
          this.lockedPriceValue = value
          this.priceBuy = this.lockedPriceValue
          this.$bvModal.hide('lock-price-modal')
        }
      } catch(e) {
        console.log(e)
      }
    },
    fetchData: function(callback=null) {
      apiRequest.get(`quotes/${this.arb.asset}/${this.arb.amount}/${this.arb.exBuy}/${this.arb.exSell}`)
      .then(response => {
        this.score = response.data['score']
        if(this.lockedPriceValue == null) {
          let priceBuy_new = Number(response.data['price_buy'])
          if(this.priceBuy < priceBuy_new) {
            this.priceBuyOpts.isGreen = true
            this.priceBuyOpts.arrowChar = '↑'
            setTimeout(() => {
              this.priceBuyOpts.isGreen = false
            }, 1000)
          } else if(this.priceBuy > priceBuy_new) {
            this.priceBuyOpts.isRed = true
            this.priceBuyOpts.arrowChar = '↓'
            setTimeout(() => {
              this.priceBuyOpts.isRed = false
            }, 1000)
          }
          this.priceBuy = priceBuy_new
        } else {
          this.priceBuy = this.lockedPriceValue
        }

        let priceSell_new = Number(response.data['price_sell'])
        if(this.priceSell < priceSell_new) {
          this.priceSellOpts.isGreen = true
          this.priceSellOpts.arrowChar = '↑'
          setTimeout(() => {
            this.priceSellOpts.isGreen = false
          }, 1000)
        } else if(this.priceSell > priceSell_new) {
          this.priceSellOpts.isRed = true
          this.priceSellOpts.arrowChar = '↓'
          setTimeout(() => {
            this.priceSellOpts.isRed = false
          }, 1000)
        }
        this.priceSell = priceSell_new
        this.priceFSell = Number(response.data['price_f_sell'])
        this.maxAmountBuy = Number(response.data['max_amount_buy'])
        this.maxAmountSell = Number(response.data['max_amount_sell'])
        this.maxAmountFSell = Number(response.data['max_amount_f_sell'])
        this.spread = Number(((1 - this.priceBuy/this.priceSell) * 100).toFixed(2))
        this.spreadF = Number(((1 - this.priceBuy/this.priceFSell) * 100).toFixed(2))
        if(!this.priceBuy) {
          this.priceBkSell = null
          this.spreadF = null
        }
        if(this.arb.spotSummaryFee !== null) {
          let amount = Number(this.arb.amount)
          let bought_amount = amount / this.priceBuy
          bought_amount -= bought_amount * this.arb.spotSummaryFee / 100
          if(this.arb.withdrawalFeeFixed !== null) {
            bought_amount -= this.arb.withdrawalFeeFixed
          }
          if(this.arb.withdrawalFeePercent != null) {
            bought_amount -= bought_amount * this.arb.withdrawalFeePercent / 100
          }
          let digitsCntB = this.calcDigits(this.noExponents(this.priceBuy))
          let digitsCntS = this.calcDigits(this.noExponents(this.priceSell))
          let digitsCnt = digitsCntB > digitsCntS ? digitsCntB : digitsCntS
          let bkePrice = amount / bought_amount
          bkePrice = bkePrice.toFixed(digitsCnt)
          if(!isNaN(bkePrice)) {
            apiRequest.get(`vol_by_price/${this.arb.asset}/${this.arb.exSell}/${bkePrice}`)
            .then(response => {
              this.priceAvgSell = Number(response.data['avg_price']).toFixed(digitsCnt)
              this.amountAvgSell = Number(response.data['total_volumes']).toFixed(2)
              this.pdi = Number(response.data['pdi']).toFixed(2)
              this.priceBkSell = bkePrice
              if(bkePrice > this.priceSell) {
                this.priceBkWarning = ' — нет спреда'
              } else {
                this.priceBkWarning = ''
              }
            })
          } else {
            this.priceBkWarning = '—'
          }
        }
        if(callback != null) {
          callback()
        }
        setTimeout(this.fetchData, 2000)
      })
      .catch((e) => {
        console.log("e: ", e);
        // setTimeout(this.fetchData, 5000)
      })
    }
  }
}
</script>

<style scoped>
  .topbar {
    color: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
    background: #23a776;
  }
  .topbar2 {
    color: #fff;
    padding-top: 1px;
    padding-bottom: 2px;
    background: #0e3d2c;
  }
  .volume-bar {
    color: #fff;
    padding-top: 1px;
    font-size: 0.9em;
    padding-bottom: 2px;
    background: #0c101a;
  }
  .bid {
    color: #23a776;
  }
  .ask {
    color: #e54150;
  }
  .clickable {
    cursor: pointer;
  }
  .price-arrow-red {
    -webkit-animation: text-change-animation-red 1s 1;
  }
  .price-arrow-green {
    -webkit-animation: text-change-animation-green 1s 1;
  }
  .exchange-link {
    color: #fff;
  }
  @keyframes text-change-animation-red {
      0% { color: red; }
      100% { color: #fff }
  }
  @keyframes text-change-animation-green {
      0% { color: green; }
      100% { color: #fff }
  }
</style>

import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue, OverlayPlugin, SidebarPlugin, ModalPlugin, ButtonPlugin, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import ErrorView from './components/ErrorView.vue'
import SpreadView from './components/SpreadView.vue'
import SpreadSnotView from './components/SpreadSnotView.vue'
import TradingVue from 'trading-vue-js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './custom.scss'
import './app.css'

Vue.config.productionTip = false
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(TradingVue)
Vue.use(OverlayPlugin)
Vue.use(SidebarPlugin)
Vue.use(ButtonPlugin)
Vue.use(IconsPlugin)

export const apiUrl = process.env.VUE_APP_API_URL

export const apiRequest = axios.create({
  withCredentials: true,
  baseURL: apiUrl
})

const routes = [
  {
    path: '/hunter/:asset/:amount/:exBuy/:exSell',
    component: SpreadSnotView
  },
  {
    path: '/:asset/:amount/:exBuy/:exSell',
    component: SpreadView
  },
  {
    name: 'ErrorView',
    path: '*',
    component: ErrorView
  },
]

Vue.mixin({
  methods: {
    noExponents(value) {
      var data = String(value).split(/[eE]/);
      if (data.length == 1) return data[0];
      var z = '',
        sign = value < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;
      if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        /* eslint-disable */
        return z + str.replace(/^\-/, '');
      }
      mag -= str.length;
      while (mag--) z += '0';
      return str + z;
    }
  }
})

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  mode: 'history',
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
